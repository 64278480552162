<template>
    <div class="divider-title">
        <div class="name">{{name}}</div>
        <div class="line"></div>
    </div>
</template>
<script>
export default {
    props:['name']
}
</script>
<style lang="scss" scoped>
.divider-title {
    height:40px;
    position: relative;
    .name {
        font-size: 18px;
        font-weight: bold;
        line-height: 40px;
        background:#fff;
        position: relative;
        z-index: 10;
        display: inline-block;
        padding-right: 20px;
        color:#666;
    }
    .line {
        position: absolute;
        height:1px;
        width:100%;
        background:#ddd;
        top:20px;
        left: 0;
    }
}

</style>